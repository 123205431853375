import './components/select.js';
import './components/burger.js';
import './components/video.js';
import './components/viewsList.js';

import './components/spollers.js';
import './components/tabs.js';
import './components/replaceEl.js';
import './components/socialsMore.js';
import './components/audioPlayer.js';




